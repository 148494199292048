<template>
<div class="liveScoring" style=" margin: -15px -15px;">
<!--  LiveScoring 直播评分-->
  <div class="liveScoring-top">
    <div class="liveScoring-top-input">
      <div>年份:</div>
      <a-date-picker
          v-model="year"
          mode="year"
          v-decorator="['year']"
          placeholder="请输入"
          format="YYYY"
          class="input"
          :open="yearShowOne"
          @openChange="openChangeOne"
          @panelChange="panelChangeOne"/>
    </div>
  </div>
  <a-row >
    <a-col class="box-card" :span="6" v-for="list in thisWeekList" :key="list.id">
    <div class="card">
      <div class="card-img">
        <div class="card-icon">
          <div v-if="list.domainCode === 'yunicu'" class="quSty">
            云ICU
          </div>
          <div v-if="list.domainCode === 'ie-learning'" class="quStyI">
            IELearning
          </div>
          <div v-if="list.domainCode === 'yichimeeting'" class="quStyY">
            医驰
          </div>
          <div v-if="list.domainCode === 'aphouse'" class="quStyA">
            APnet
          </div>
          <div v-if="list.type === 'SINGLE'" class="quStyXH" >
            普通小会
          </div>
          <div v-if="list.type === 'SERIES'" class="quStyX">
            系列会
          </div>
          <div v-if="list.type === 'CONVENTION'" class="quStyD">
            多会场
          </div>
        </div>
        <img class="img" :src="list.coverUrl"/>
        <div class="card-top">负责人：{{list.leaderSales}} {{list.leaderOperate}}</div>
      </div>
      <div class="title">
        <div class="title-t">{{list.title}}</div>
        <div class="title-liveTime">直播时间：{{list.showTime}}</div>
      </div>
      <div class="bottom" @click="openModel">评分</div>
    </div>
    </a-col>
</a-row>
<!--  对话框--------------------------------->
  <a-drawer
      title="开场图"
      :width="600"
      :visible="visible"
      :body-style="{ paddingBottom: '80px' }"
      @close="closeBtn"
  >
    <a-form-model
        layout="vertical"
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
    >
      <a-form-model-item  label="直播评分（评分越高效果越好）" prop="radio">
        <a-radio-group style="width: 600px" v-model="form.radio">
          <a-radio-button v-for="radio in radioList" :value="radio.value">
            {{radio.title}}
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item  label="是否发生以下问题"  prop="checkbox">
        <a-checkbox-group v-model="checkedList" :options="plainOptions" @change="onChange">
        </a-checkbox-group>
        <a-checkbox :indeterminate="indeterminate" :checked="checkAll" @change="onCheckAllChange">
          Check all
        </a-checkbox>
      </a-form-model-item>
      <a-form-model-item  label="小结（可描述客户好评，流程优化，不常见问题等）" prop="inputValue">
        <a-textarea
            v-model="form.inputValue"
            placeholder="请输入"
            :auto-size="{ minRows: 3, maxRows: 5 }"
        />
      </a-form-model-item>
    </a-form-model>
    <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
    >
      <a-button :style="{ marginRight: '8px' }" @click="closeBtn">
        取消
      </a-button>
      <a-button type="primary" @click="onSubmit">
        确认
      </a-button>
    </div>
  </a-drawer>
</div>
</template>

<script>
import moment from "moment";
import {current_date} from "@/utils/currentDate";
const plainOptions = ['Apple', 'Pear', 'Orange'];
export default {
name: "LiveScoring.vue",
  data(){

    return{
      year:current_date(),
      yearShowOne:false,
      thisWeekList:[
        {
          domainCode:'yunicu',
          id:1,
          type:'SINGLE',
          leaderSales:'SINGLE',
          title:'leaderOperate',
          leaderOperate:'leaderOperate',
          showTime:'2022-10-11',
          coverUrl:'https://img0.baidu.com/it/u=1705694933,4002952892&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=281',
        },
        {
          domainCode:'yunicu',
          id:2,
          type:'SINGLE',
          leaderSales:'SINGLE',
          title:'leaderOperate',
          leaderOperate:'leaderOperate',
          showTime:'2022-10-11',
          coverUrl:'https://img0.baidu.com/it/u=1705694933,4002952892&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=281',
        },
      ],
      visible:false,
      form:{
        radio:undefined,
        checkedList:[],
        inputValue:'',
      },
      rules: {
        radio: [{ required: true, message: '请选择', trigger: 'blur' }],
        checkbox: [{ required: true, message: '请选择', trigger: 'blur' }],
        inputValue: [{ required: true, message: '请输入', trigger: 'blur' }],
      },
      radioList:[
        {
          title:'1分',
          value:1
        },{
          title:'2分',
          value:2
        },{
          title:'3分',
          value:3
        },{
          title:'4分',
          value:4
        },{
          title:'5分',
          value:5
        },{
          title:'6分',
          value:6
        },{
          title:'7分',
          value:7
        },{
          title:'8分',
          value:8
        },{
          title:'9分',
          value:9
        },{
          title:'10分',
          value:10
        },
      ],
      checkboxList:[
        {
          title:'选项一',
        },{
          title:'选项一',
        },{
          title:'选项一',
        },{
          title:'选项一',
        },{
          title:'选项一',
        },{
          title:'选项一',
        },{
          title:'选项一',
        },
      ],

      labelCol: { span: 20},
      wrapperCol: { span: 14 },
      checkedList: [],
      indeterminate: true,
      checkAll: false,
      plainOptions,
    }
  },
  methods:{
    onCheckAllChange(e) {
      Object.assign(this, {
        checkedList: e.target.checked ? plainOptions : [],
        indeterminate: false,
        checkAll: e.target.checked,
      });
      this.form.checkedList = this.checkedList
      console.log(this.form.checkedList)
    },
    onChange(checkedList) {
      this.indeterminate = !!checkedList.length && checkedList.length < plainOptions.length;
      this.checkAll = checkedList.length === plainOptions.length;
      this.form.checkedList = checkedList

      console.log(this.form.checkedList)
    },
    // 弹出日历和关闭日历的回调
    openChangeOne(status) {
      if (status) {
        this.yearShowOne = true;
      }else {
        this.yearShowOne = false;
      }
    },
    // 得到年份选择器的值
    panelChangeOne(value) {
      this.yearShowOne = false;
      this.year = value;
      console.log(value)
    },
    closeBtn(){
      this.visible = false;
      this.$refs.ruleForm.resetFields();
    },
    onSubmit(){
      this.$refs.ruleForm.validate(valid => {
        if (valid) {

        } else {
          return false;
        }
      });
    },
    openModel(){
      this.visible = true
    },
  },
  created() {
    this.$store.dispatch('setManageHeaderTitle',"直播评分")
  },
}
</script>

<style scoped lang="scss">
.liveScoring{
  height: 105%;
  background-color: #f0f2f5;
  .liveScoring-top{
    width: 100%;
    height: 85px;
    background-color: #fff;
    border-bottom: 15px solid #F0F2F5;
    display: flex;
    justify-content: space-between;
    .liveScoring-top-input{
      display: flex;
      justify-content: center;
      height: 50px;
      line-height: 30px;
      padding-top: 20px;
      padding-left: 20px;
      .input{
        width: 200px;
        margin-left: 10px;
      }
    }
  }
  .box-card{
    margin-bottom: 10px;
  .card{
    background-color: #fff;
    border:1px solid #e8e7e7;
    width: 98%;
    height: 100%;
    margin: auto;
    .card-img{
      width: 100%;
      position: relative;
      .card-icon{
        display: flex;
        position: absolute;
        top: 0;
        padding: 10px;
        .quSty{
          width: 50px;
          height: 30px;
          line-height: 30px;
          border: 1px solid #91D5FF;
          background: #E6F7FF;
          color: #1890FF;
          text-align: center;
          border-radius: 5px;

        }.quStyI{
           width: 100px;
           height: 30px;
           line-height: 30px;
           border: 1px solid #D3ADF7;
           background: #F9F0FF;
           color: #722ED1;
           text-align: center;
           border-radius: 5px;
         }.quStyY{
            width: 50px;
            height: 30px;
            line-height: 30px;
            border: 1px solid #FFA39E;
            background: #FEF0EF;
            color: #F6222D;
            text-align: center;
            border-radius: 5px;
          }.quStyA{
             width: 50px;
             height: 30px;
             line-height: 30px;
             border: 1px solid #87E8DE;
             background: #E6FFFB;
             color: #13C2C2;
             text-align: center;
             border-radius: 5px;
           }.quStyX{
              width: 70px;
              height: 30px;
              line-height: 30px;
              border: 1px solid #91e887;
              background: #eff5ee;
              color: #35c924;
              text-align: center;
              border-radius: 5px;
              margin-left: 10px;
            }.quStyXH{
               width: 70px;
               height: 30px;
               line-height: 30px;
               border: 1px solid #444444;
               background: #e4e5e5;
               color: #444444;
               text-align: center;
               border-radius: 5px;
               margin-left: 10px;
             }.quStyD{
                width: 70px;
                height: 30px;
                line-height: 30px;
                border: 1px solid #FFBB96;
                background: #FFF2E8;
                color: #FFBB96;
                text-align: center;
                border-radius: 5px;
                margin-left: 10px;
              }
      }
      .card-top{
        height: 30px;
        line-height: 30px;
        color: #fff;
        padding-left: 10px;
        width: 100%;
        background: rgba(0,0,0,0.5);
        position: absolute;
        bottom: 0;
      }
      .img{
        width: 100%;
        height: 150px;
        border:1px solid #f5f5f5;
      }
    }
    .title{
      height: 70px;color: #333333;padding: 10px;
      .title-t{
        font-size: 14px;
        font-weight: bold;
        width: 100%;
        display: -webkit-box;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        word-break: break-all;
      }
      .title-liveTime{
        font-size: 12px;color: #8c8a8a;
      }
    }
    .bottom{
      font-size: 12px;
      background: #f5f5f5;
      height: 40px;
      line-height: 40px;
      color: #8c8a8a;
      display: flex;
      justify-content: space-around;
      cursor: pointer;
    }
    .bottom:hover{
      color: #45A5E6;
    }
  }
  }
}
</style>